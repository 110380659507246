<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="organization"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="waterTestingRequest.org_id"
                            :options="organizationList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option value="" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Lab" vid="laboratory_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="laboratory_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('irriWaterTest.lab')}}<span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="waterTestingRequest.laboratory_id"
                            :options="laboratoryList"
                            id="laboratory_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option value="" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Testing Type Id" vid="testing_type_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="testing_type_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('farmerOperator.testing_type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="waterTestingRequest.testing_type_id"
                            :options="testingTypeList"
                            id="testing_type_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option value="">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Testing Parameter" vid="parameterList" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="parameterList"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('externalUserIrrigation.water_testing_parameter') }} <span class="text-danger">*</span>
                                </template>
                                <v-select
                                    id="parameterList"
                                    v-model="waterTestingRequest.water_testing_parameter_id"
                                    multiple
                                    :options="testingParameterList"
                                    label="text"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </v-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="from_date" vid="from_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="from_date"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('externalUserIrrigation.from_date_schedule') }} <span class="text-danger">*</span>
                            </template>
                            <flat-pickr class="form-control"
                            v-model="waterTestingRequest.from_date"
                            placeholder="Select Date"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></flat-pickr>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="to_date" vid="to_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="to_date"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('externalUserIrrigation.to_date_schedule') }} <span class="text-danger">*</span>
                            </template>
                            <flat-pickr class="form-control"
                            v-model="waterTestingRequest.to_date"
                            placeholder="Select Date"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></flat-pickr>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button @click="waterTestingRequest.final_pay = 1" type="submit" variant="primary">{{ saveBtnName}}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { waterTestingStoreByAdmin, waterTestingRequestUpdate } from '../../api/routes'
import customObject from '../../../../../store/custom'

export default {
  name: 'WaterTestingForm',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      loadingState: true,
      payLoading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      waterTestingRequest: {
        water_testing_parameter_id: [],
        org_id: '',
        laboratory_id: '',
        testing_type_id: '',
        from_date: '',
        to_date: ''
      },
      laboratoryList: [],
      parameterList: [],
      prices: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getwaterTestingRequestData()
      this.waterTestingRequest = tmp
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    testingParameterList: function () {
      const testingParameterList = this.$store.state.IrriConfig.commonObj.waterTestingParameterList.filter(item => item.status === 0)
      const testingType = this.waterTestingRequest.testing_type_id ? this.waterTestingRequest.testing_type_id : 0
      const orgId = this.waterTestingRequest.org_id ? this.waterTestingRequest.org_id : 0
      const data = testingParameterList.filter(item => item.org_id === orgId && item.testing_type_id === testingType)
      return data
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    testingTypeList: function () {
      const listObject = customObject.testingTypeList
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.name_bn }
        } else {
          return { value: obj.id, text: obj.name }
        }
      })
      return tmpList
    }
  },
  watch: {
    'waterTestingRequest.org_id': function (newVal, oldVal) {
      this.getLaboratories(newVal)
    }
  },
  methods: {
    getwaterTestingRequestData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${waterTestingRequestUpdate}/${this.id}`, this.waterTestingRequest)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, waterTestingStoreByAdmin, this.waterTestingRequest)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false
      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getLaboratories (orgId) {
      RestApi.getData(irriSchemeServiceBaseUrl, 'laboratory/list-all', null).then(response => {
        if (response.success) {
          const laboratoryData = response.data.filter(lab => lab.org_id === orgId)
          this.laboratoryList = laboratoryData.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.id, text: obj.laboratory_name_bn }
            } else {
              return { value: obj.id, text: obj.laboratory_name }
            }
          })
        }
      })
    }
  }
}
</script>
