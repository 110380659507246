<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('farmerOperator.water_testing_request') + ' ' + $t('globalTrans.list')}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(sample_number)="data">
                      <span>{{ $n(data.item.sample_number, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(from_date)="data">
                      <span>{{ data.item.from_date | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(to_date)="data">
                      <span>{{ data.item.to_date | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(updated_at)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat}}</span>
                    </template>
                    <template v-slot:cell(view)="data">
                      <b-button class="btn btn-success" @click="pdfExport(data.item)">{{ $t('globalTrans.pdf') }}</b-button>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button class="mr-1" title="View Water Testing Details" v-b-modal.modal-detail variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <a v-if="data.item.status === 5 && data.item.water_test_reports != null" :href="irriSchemeServiceBaseUrl + 'download-attachment?file=uploads/water-test-reports/original/' + data.item.water_test_reports.attachment" class="btn btn-success"><i class="fa fa-cloud" aria-hidden="true"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-detail" size="lg" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailModal :id="editItemId" :key="editItemId" :item="item"/>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form'
import DetailModal from './DetailModal'
import { waterTestingManualEntryList, waterTestingRequestToggleStatus } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ExportPdf from '@/Utils/export-pdf'
import customObject from '../../../../../store/custom'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV,
    DetailModal
  },
  data () {
    return {
      irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl,
      search: {
        org_id: '0',
        auth_id: this.$store.state.Auth.authUser.user_id,
        status: '0',
        sample_number: '',
        testing_type_id: '0'
      },
      item: '',
      rows: []
    }
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    testingTypeList: function () {
      const ObjectData = customObject.testingTypeList
      return ObjectData.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.name_bn }
        } else {
          return { value: obj.id, text: obj.name }
        }
      })
    },
    waterTestingStatusList: function () {
      const ObjectData = customObject.waterTestingStatusList
      return ObjectData.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.name_bn }
        } else {
          return { value: obj.id, text: obj.name }
        }
      })
    },
    formTitle () {
      return (this.testId === 0) ? this.$t('farmerOperator.water_testing_request_entry') : this.$t('farmerOperator.water_testing_request') + ' ' + this.$t('globalTrans.modify')
    },
    detailTitle () {
      return this.$t('farmerOperator.water_testing_request_detail')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.application_id'), class: 'text-left' },
          { label: this.$t('org_pro.organization'), class: 'text-left' },
          { label: this.$t('irriWaterTest.responsible_lab'), class: 'text-left' },
          { label: this.$t('farmerOperator.testing_type'), class: 'text-left' },
          { label: this.$t('farmerOperator.sample_number'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.from_date'), class: 'text-center' },
          { label: this.$t('globalTrans.to_date'), class: 'text-center' },
          { label: this.$t('pump_install.created_date'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'org_name_bn' },
          { key: 'laboratory_name_bn' },
          { key: 'testing_type_name_bn' },
          { key: 'sample_number' },
          { key: 'testing_status_name_bn' },
          { key: 'from_date' },
          { key: 'to_date' },
          { key: 'updated_at' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'org_name' },
          { key: 'laboratory_name' },
          { key: 'testing_type_name' },
          { key: 'sample_number' },
          { key: 'testing_status_name' },
          { key: 'from_date' },
          { key: 'to_date' },
          { key: 'updated_at' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    pdfExport (data) {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('externalUserIrrigation.application_id'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), bold: true },
          { text: this.$t('globalTrans.application_date'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), bold: true },
          { text: this.$t('farmerOperator.sample_number'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), bold: true },
          { text: this.$t('farmerOperator.sample_serial'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), bold: true },
          { text: this.$t('globalTrans.status'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), bold: true },
          { text: this.$t('irriPumpInformation.operator_location'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), bold: true }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Complain Report' : 'অভিযোগ  রিপোর্ট'
      const columnWids = ['*', '*', '*', '*', '*', '*']
      ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', data.org_id, reportTitle, rowData, columnWids)
    },
    getPdfData () {
      const keys = [
        { key: this.$i18n.locale === 'en' ? 'application_id' : 'application_id' },
        { key: this.$i18n.locale === 'en' ? 'name' : 'name_bn' },
        { key: this.$i18n.locale === 'en' ? 'created_at' : 'created_at' },
        { key: this.$i18n.locale === 'en' ? 'sample_number' : 'sample_number' },
        { key: this.$i18n.locale === 'en' ? 'sample_serial' : 'sample_serial' },
        { key: this.$i18n.locale === 'en' ? 'testing_status_name' : 'testing_status_name_bn' }
      ]
      return keys
    },
    details (item) {
      this.testId = item.id
      this.item = item
    },
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, waterTestingRequestToggleStatus, item, 'common', 'waterTestingManualEntryList')
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(irriSchemeServiceBaseUrl, waterTestingManualEntryList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const organizationList = this.organizationList
      const testingTypeList = customObject.testingTypeList
      const waterTestingStatusList = customObject.waterTestingStatusList
      const waterTestingRequestData = data.data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const testingTypeObject = testingTypeList.find(testingType => testingType.id === item.testing_type_id)
        const statusListObject = waterTestingStatusList.find(testingStatus => testingStatus.id === item.status)
        const orgData = {
          org_name: orgObject.text,
          org_name_bn: orgObject.text_bn
        }
        const TestingTypeData = {
          testing_type_name: testingTypeObject.name,
          testing_type_name_bn: testingTypeObject.name_bn
        }
        const waterTestingData = {
          testing_status_name: statusListObject.name,
          testing_status_name_bn: statusListObject.name_bn
        }
        return Object.assign({}, item, orgData, TestingTypeData, waterTestingData)
      })
      return waterTestingRequestData
    }
  }
}
</script>
